import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import api from "../../Component/api/api";
import { useState } from "react";
import { useEffect } from "react";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  search: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0e0e0",
    width: "20%",
    marginRight: 20,
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
}));

export default function MemberList() {
  const classes = useStyles();

  const [Member, setMember] = useState([]);

  const fetchEmpData = async () => {
    try {
      const result = await api.post("/api/users/user");

      const _result = result.data.results;
      setMember(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  useEffect(() => {
    fetchEmpData();
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={1} sx={{ height: "88vh" }}>
        {/* <div className={classes.Padding}>
          <h3>ข้อมูลส่วนตัว</h3>
          <h5 style={{ marginTop: 20, paddingLeft: 10 }}>
            ชื่อ:<span>&nbsp;</span>
            {Member.fistName}
            <span>&nbsp;&nbsp;</span>
            {Member.lastName}
          </h5>
          <h5 style={{ marginTop: 20, paddingLeft: 10 }}>
            รหัสพนักงาน:<span>&nbsp;</span>
            {Member.empNo}
          </h5>
          <h5 style={{ marginTop: 20, paddingLeft: 10 }}>
            ตำแหน่ง:<span>&nbsp;</span>
            {Member.roleName}
          </h5>
        </div> */}
      </Paper>
    </div>
  );
}
