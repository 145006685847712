import {
  Button,
  Divider,
  Grid,
  Paper,
  CircularProgress,
  TextField,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import qs from "qs";
import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../../Component/api/api";
import DialogForAPI from "../../Component/DialogForAPI";
import FileUploadService from "../../Services/FileUploadService";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
  fileUpload: {
    cursor: "pointer",
    textDecoration: "underline",
    color: "rgb(16, 148, 216)",
  },
}));

export default function DetailTicket() {
  const token = localStorage.getItem("token");
  const history = useHistory();

  const classes = useStyles();
  let { id } = useParams();

  const [Remark, setRemark] = useState();
  const [open, setOpen] = useState(false);
  const [message, setmessage] = useState("");
  const [Detail, setDetail] = useState([]);
  const [Status, setStatus] = useState("");
  const [FileUpload, setFileUpload] = useState([]);
  const [HeadDetail, setHeadDetail] = useState([]);
  const [RemarkErr, setRemarkErr] = useState(false);
  const [Loading, setLoading] = useState(false);

  const saveReply = async () => {
    setLoading(true);

    try {
      await FileUploadService.uploadFileForBvoice(FileUpload).then(
        async (res) => {
          if (res) {
            const VoiceFile = res.map((x) => ({
              VoiceFileID: null,
              FileId: x.data.results.fileId,
              Deleted: false,
            }));
            const body = {
              VoiceDetID: null,
              VoiceId: HeadDetail[0]?.voiceId,
              Remark: Remark,
              UserType: "A",
              Deleted: false,
              VoiceFile: VoiceFile,
            };

            try {
              await api.post("api/bvoice/detail/add", body).then((res) => {
                if (res) {
                  setOpen(true);
                  setmessage(res.data.message);
                  setTimeout(async () => {
                    await fetchDetail(id);
                    setRemark("");
                    handleCloseDialog();
                    setOpen(false);
                    setFileUpload([]);
                    setLoading(false);
                  }, 2000);
                }
              });
            } catch (error) {
              console.log("error => ", error);
              setLoading(false);
            }
          }
        }
      );
    } catch (error) {
      console.log("Could not upload the file!", error);
      setFileUpload([]);
    }
  };

  const fetchDetailHead = async (VoiceId) => {
    try {
      const params = qs.stringify({
        // ...(VoiceNo && { VoiceNo }),
        ...(VoiceId && { VoiceId }),
      });

      const result = await api.get(`/api/bvoice/select?${params}`);
      const _result = result.data.results;
      setHeadDetail([_result]);
      setStatus(_result.status);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const fetchDetail = async (VoiceId) => {
    try {
      const id = VoiceId;

      const result = await api.get(
        `/api/bvoice/detail/list?VoiceId=${id}&isBackend=${true}`
      );
      const _result = result.data.results;
      setDetail(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const addAndEditHead = async (statusUpdate) => {
    const body = {
      VoiceId: HeadDetail[0].voiceId,
      VoiceTitle: HeadDetail[0].voiceTitle,
      TopicId: HeadDetail[0].topicId,
      TopicSubId: HeadDetail[0].topicSubId,
      VoiceDate: HeadDetail[0].voiceDate,
      Tel: HeadDetail[0].tel,
      Email: HeadDetail[0].email,
      Status: statusUpdate,
    };

    try {
      await api.post("api/bvoice/head/add", body).then((res) => {
        if (res) {
          setOpen(true);
          setmessage(res.data.message);
          setTimeout(() => {
            fetchDetail(res.data.results?.voiceId);
            handleCloseDialog();
            setOpen(false);
          }, 2000);
        }
      });
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const handleClickUpdateHead = (status) => {
    setStatus(status);
    addAndEditHead(status);
  };

  const handleCloseDialog = (props) => {
    setOpen(props);
  };

  useEffect(() => {
    if (id) {
      fetchDetail(id);
      fetchDetailHead(id);
    }
  }, []);

  useEffect(() => {
    if (!token) {
      window.location = "/login";
    }
  }, []);

  const UploadFile = () => {
    document.getElementById("selectFile").click();
  };

  const selectedFile = (event) => {
    let currentFiles = event.target.files[0];
    const x = { delete: false };
    let numFile = { num: FileUpload.length + 1 };
    let _currentFiles = Object.assign(currentFiles, x, numFile);

    // currentFiles.forEach((x) => {
    //   x.delete = false;
    // });

    fileSelected(_currentFiles);

    event.target.value = null;
  };

  const fileSelected = (file) => {
    setFileUpload([...FileUpload, file]);
  };

  const deleteFile = (File, index) => {
    const files = FileUpload.map((item, index2) =>
      File.num === item.num ? Object.assign(item, { delete: true }) : item
    );
    setFileUpload(files);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={1} style={{ height: "88vh", overflow: "auto" }}>
        <div className={classes.Padding}>
          <p style={{ color: "red" }}>B-Voice</p>
          <h3>B-Voice</h3>

          <Grid xs={12}>
            <div>
              <div
                style={{
                  marginTop: 20,
                  border: "1px solid #e0e0e0",
                  padding: 20,
                  margin: "20px 5px 20px 5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <h4>B-Voice</h4>
                    <h4 style={{ color: "red", marginLeft: 20 }}>
                      {HeadDetail[0]?.voiceNo}
                    </h4>
                  </div>

                  {Status === "C" && (
                    <Button
                      variant="contained"
                      style={{
                        color: "white",
                        backgroundColor: "#00A88F",
                        borderColor: "transparent",
                        width: 150,
                      }}
                      component="span"
                      onClick={() => handleClickUpdateHead("A")}
                    >
                      Open
                    </Button>
                  )}
                </div>
                <Divider sx={{ margin: "20px 0px 20px 0px" }} />
                {HeadDetail?.map((x) => {
                  return (
                    <Grid container>
                      <Grid item xs={6}>
                        <p style={{ color: "red" }}>
                          {x.topicName}
                          {x.topicSubName !== "" ? " | " : null}
                          {x.topicSubName}
                        </p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <p>
                          {x.email}
                          <span>&nbsp;&nbsp;&nbsp;</span>|
                          <span>&nbsp;&nbsp;&nbsp;</span>
                          {x.tel}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p>{x.voiceDetail[0]?.remark}</p>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          justifyContent: "end",
                        }}
                      >
                        <p>
                          {moment(x.voiceDetail[0]?.createDate).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        xs={1}
                      >
                        <p>
                          ไฟล์แนบ:<span>&nbsp;</span>
                        </p>
                      </Grid>
                      <Grid item sx={{ display: "flex" }} xs={11}>
                        {x.voiceDetail[0]?.voiceFile.map((file, index) => {
                          return (
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: "#F5F5F5",
                                margin: 5,
                              }}
                            >
                              <span
                                className={classes.fileUpload}
                                onClick={() => (window.location = file.path)}
                              >
                                {file.fileName}
                              </span>
                              <span>&nbsp;</span>
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>
                  );
                })}
              </div>

              {Detail.filter((x) => x.voiceDetID !== Detail[0]?.voiceDetID).map(
                (x) => {
                  return (
                    <div
                      style={
                        x.userType === "A"
                          ? {
                              marginTop: 20,
                              border: "1px solid red",
                              padding: 20,
                              margin: "20px 15px 20px 15px",
                            }
                          : {
                              marginTop: 20,
                              border: "1px solid #e0e0e0",
                              padding: 20,
                              margin: "20px 15px 20px 15px",
                            }
                      }
                    >
                      <p>{x.remark}</p>

                      <Grid container>
                        {x.voiceFile.length > 0 && (
                          <Grid
                            item
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            xs={1}
                          >
                            <p>
                              ไฟล์แนบ:<span>&nbsp;</span>
                            </p>
                          </Grid>
                        )}

                        <Grid item sx={{ display: "flex" }} xs={11}>
                          {x.voiceFile?.map((x) => {
                            return (
                              <div
                                style={{
                                  padding: 10,
                                  backgroundColor: "#F5F5F5",
                                  margin: 5,
                                }}
                              >
                                <span
                                  className={classes.fileUpload}
                                  onClick={() => (window.location = x.path)}
                                >
                                  {x.fileName}
                                </span>
                                <span>&nbsp;</span>
                              </div>
                            );
                          })}
                        </Grid>
                      </Grid>

                      <Divider sx={{ margin: "20px 0px 20px 0px" }} />
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <p>{moment(x.createDate).format("DD/MM/YYYY")}</p>
                        <span>&nbsp;&nbsp;</span>
                        <p>
                          -<span>&nbsp;&nbsp;</span>
                          {x.firstName}
                        </p>
                      </Grid>
                    </div>
                  );
                }
              )}

              {Status === "A" && (
                <Grid>
                  <Paper
                    elevation={2}
                    sx={{ padding: 3, margin: "20px 5px 20px 5px" }}
                  >
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1,
                        }}
                      >
                        <TextField
                          aria-label="minimum height"
                          minRows={5}
                          maxRows={5}
                          multiline
                          onChange={(e) => {
                            setRemarkErr(false);
                            setRemark(e.target.value);
                          }}
                          value={Remark}
                          placeholder="remark"
                          required
                          error={RemarkErr}
                        />
                        {RemarkErr && (
                          <p style={{ color: "red" }}>* pleses enter remark</p>
                        )}
                      </div>
                    </Grid>
                    <Grid container xs={12}>
                      {FileUpload?.filter((x) => !x.delete).map(
                        (file, index) => {
                          return (
                            <div
                              style={{
                                padding: 10,
                                backgroundColor: "#F5F5F5",
                                margin: 5,
                              }}
                            >
                              {file?.name}
                              <span>&nbsp;</span>
                              <Button
                                style={{
                                  color: "black",
                                  borderColor: "transparent",
                                  minWidth: 20,
                                }}
                                onClick={() => deleteFile(file, index)}
                              >
                                <span class="material-icons-outlined">
                                  clear
                                </span>
                              </Button>
                            </div>
                          );
                        }
                      )}
                    </Grid>

                    <Grid
                      container
                      sx={{
                        marginTop: 2,
                      }}
                    >
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          style={{
                            color: "black",
                            borderColor: "lightgray",
                            width: "60%",
                            marginRight: 20,
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          onClick={() => UploadFile()}
                          onChange={(event) => selectedFile(event)}
                        >
                          <input
                            id="selectFile"
                            type="file"
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          Attach files
                          <span class="material-icons-outlined">
                            attach_file
                          </span>
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <Button
                          variant="outlined"
                          style={{
                            color: "#FF0000",
                            borderColor: "#FF0000",
                            width: 150,
                            marginRight: 20,
                          }}
                          onClick={() =>
                            !Remark ? setRemarkErr(true) : saveReply()
                          }
                          type="submid"
                        >
                          {Loading ? (
                            <CircularProgress
                              sx={{
                                color: "red",
                              }}
                              size={24}
                            />
                          ) : (
                            "Reply"
                          )}
                        </Button>
                        <Button
                          variant="contained"
                          style={{
                            color: "white",
                            backgroundColor: "#FF0000",
                            borderColor: "transparent",
                            width: 150,
                          }}
                          component="span"
                          onClick={() => handleClickUpdateHead("C")}
                        >
                          Closed B-Voice
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              )}

              <DialogForAPI
                DialogOpen={open}
                DialogClose={handleCloseDialog}
                message={message}
              />
            </div>
          </Grid>
        </div>
      </Paper>
    </div>
  );
}
