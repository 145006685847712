import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import "./App.css";
import PrivateRoute from "./Component/PrivateRoute";
import withAuthLayout from "./HoC/withAuthLayout";
import { getActions } from "./redux/modules/actionSlice";
import AddMember from "./Screens/B-360/AddMember";
import AllRound from "./Screens/B-360/AllRound";
import MemberList from "./Screens/B-360/MemberList";
import AllUser from "./Screens/B-Admin/AllUser";
import EditUser from "./Screens/B-Admin/EditUser";
import ManagePermissions from "./Screens/B-Admin/ManagePermissions";
import Role from "./Screens/B-Admin/Role";
import SetPermissions from "./Screens/B-Admin/SetPermissions";
import AddLink from "./Screens/B-Connect/AddLink";
import AllLink from "./Screens/B-Connect/AllLink";
import AllPoint from "./Screens/B-Point/AllPoint";
import ReportExcel from "./Screens/B-Report/Report";
import AddNewReward from "./Screens/B-Reward/AddNewReward";
import AllReward from "./Screens/B-Reward/AllReward";
import OrderList from "./Screens/B-Reward/OrderList";
import ConfigurationTopic from "./Screens/B-Voice/ConfigurationTopic";
import DetailTicket from "./Screens/B-Voice/DetailTicket";
import Ticket from "./Screens/B-Voice/Ticket";
import login from "./Screens/login";
import AllSubject from "./Screens/News/AllSubject";
import Information from "./Screens/News/Information";
import api from "./Component/api/api";

export default function AppRouter() {
  const { list: allActions } = useSelector((state) => state.action);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getActions());

    const checkwork = async () => {
      try {
        await api.post("/api/users/checkwork").then((res) => {
          if (res.data.results?.workStatus === false) {
            localStorage.removeItem("token");
            window.location.href = "/login";
          }
        });
      } catch (error) {
        console.log("error => ", error);
      }
    };

    if (window.location.pathname !== "/login") {
      checkwork();
    }
  }, []);

  return (
    <>
      <Router>
        <div
          className="font-face-gm"
          style={{
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#F7F8FA",
            height: "100vh",
          }}
        >
          <React.Fragment>
            <Switch>
              <div style={{ width: "100%" }}>
                <Route path="/login" component={login} />

                {allActions?.find((x) => x.actionNo === "A003") && (
                  <Route
                    path="/Information"
                    component={withAuthLayout(Information)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A004") && (
                  <Route
                    path="/AllSubject"
                    component={withAuthLayout(AllSubject)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A003") && (
                  <Route
                    exact
                    path="/EditInformation/:id"
                    component={withAuthLayout(Information)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A005") && (
                  <Route path="/Order" component={withAuthLayout(OrderList)} />
                )}

                {allActions?.find((x) => x.actionNo === "A006") && (
                  <Route
                    path="/AllReward"
                    component={withAuthLayout(AllReward)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A007") && (
                  <Route
                    path="/AddNewReward"
                    component={withAuthLayout(AddNewReward)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A006") && (
                  <Route
                    exact
                    path="/EditReward/:id"
                    component={withAuthLayout(AddNewReward)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A008") && (
                  <Route
                    path="/RoundList"
                    component={withAuthLayout(AllRound)}
                  />
                )}

                <Route path="/Member" component={withAuthLayout(MemberList)} />
                <Route
                  path="/AddMember"
                  component={withAuthLayout(AddMember)}
                />
                <Route
                  path="/EditRound/:id"
                  component={withAuthLayout(AddMember)}
                />

                {allActions?.find((x) => x.actionNo === "A009") && (
                  <Route path="/AllLink" component={withAuthLayout(AllLink)} />
                )}

                {allActions?.find((x) => x.actionNo === "A010") && (
                  <Route path="/AddLink" component={withAuthLayout(AddLink)} />
                )}

                {allActions?.find((x) => x.actionNo === "A009") && (
                  <Route
                    exact
                    path="/EditLink/:id"
                    component={withAuthLayout(AddLink)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A011") && (
                  <Route
                    path="/AllPoint"
                    component={withAuthLayout(AllPoint)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A012") && (
                  <Route
                    path="/Report"
                    component={withAuthLayout(ReportExcel)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A025") && (
                  <Route path="/Ticket" component={withAuthLayout(Ticket)} />
                )}

                {allActions?.find((x) => x.actionNo === "A025") && (
                  <Route
                    path="/TicketDetail/:id"
                    component={withAuthLayout(DetailTicket)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A026") && (
                  <Route
                    path="/ConfigurationTopic"
                    component={withAuthLayout(ConfigurationTopic)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A013") && (
                  <Route path="/AllUser" component={withAuthLayout(AllUser)} />
                )}

                {allActions?.find((x) => x.actionNo === "A013") && (
                  <Route
                    exact
                    path="/EditUser/:id"
                    component={withAuthLayout(EditUser)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A015") && (
                  <Route
                    path="/ManagePermissions"
                    component={withAuthLayout(ManagePermissions)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A024") && (
                  <Route
                    path="/SetPermissions"
                    component={withAuthLayout(SetPermissions)}
                  />
                )}

                {allActions?.find((x) => x.actionNo === "A014") && (
                  <Route path="/Role" component={withAuthLayout(Role)} />
                )}

                <Route
                  exact
                  path="/"
                  render={() => {
                    return localStorage.getItem("token") ? (
                      // role ? (
                      <Redirect to="/AllSubject" />
                    ) : (
                      // ) : (
                      //   <Redirect to="/NoAccess" />
                      // )
                      <Redirect to="/login" />
                    );
                  }}
                />
                <Route
                  path="*"
                  render={() => {
                    return localStorage.getItem("token") ? (
                      // role ? (
                      <Redirect to={`${window.location.pathname}`} />
                    ) : (
                      // ) : (
                      //   <Redirect to="/NoAccess" />
                      // )
                      <Redirect to="/login" />
                    );
                  }}
                />
              </div>
            </Switch>
          </React.Fragment>
        </div>
      </Router>
    </>
  );
}
