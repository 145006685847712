import {
  Paper,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  DialogTitle,
  CircularProgress,
  Select,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import api from "../../Component/api/api";
import qs from "qs";
import DialogForAPI from "../../Component/DialogForAPI.js";
import "../css/styles.css";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
}));
export default function SetPermissions() {
  const classes = useStyles();

  const [MenuList, setMenuList] = useState([]);
  const [SubMenuNo, setSubMenuNo] = useState("");
  const [MenuNo, setMenuNo] = useState("");
  const [ActionList, setActionList] = useState([]);
  const [Name, setName] = useState("");
  const [OpenEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [Status, setStatus] = useState("Y");
  const [message, setmessage] = useState("");
  const [openCancel, setOpenCancel] = useState(false);
  const [ActionNo, setActionNo] = useState("");
  const [Loading, setLoading] = useState(false);
  const [RoleList, setRoleList] = useState([]);
  const [detail, setDetail] = useState([]);
  const [ActionRole, setActionRole] = useState([]);

  const fetchRoleList = async () => {
    try {
      const result = await api.get("api/setting/role/list");
      const _result = result.data.results.map((x) => ({
        ...x,
        // selected: false,
      }));
      setRoleList(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const fetchActionRole = async () => {
    try {
      const result = await api.get("api/setting/actions/role");
      const _result = result.data.results;
      setActionRole(_result);
      // CheckBoxIsTrue(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const fetchMenuAll = async () => {
    try {
      const result = await api.get("api/setting/menu/all");
      const _result = result.data.results;
      setMenuList(_result);
      setActionNo("");
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const fetchActionMenu = async () => {
    try {
      const params = qs.stringify({
        ...(MenuNo && { MenuNo }),
        ...(SubMenuNo && { SubMenuNo }),
      });

      const result = await api.get(`api/setting/actionmenu/list?${params}`);
      const _result = result.data.results;
      setActionList(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const EditAndAddAction = async () => {
    const body = {
      MenuNo: MenuNo,
      SubMenuNo: SubMenuNo,
      ActionNo: ActionNo,
      ActionName: Name,
      Status: Status,
    };

    try {
      const result = await api
        .post("api/setting/actionmenu/add", body)
        .then((res) => {
          if (res) {
            setOpen(true);
            setmessage(res.data.message);
            setTimeout(() => {
              handleClose();
              setOpen(false);
            }, 2000);
            fetchActionMenu();
          }
        });
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const saveRole = async () => {
    setLoading(true);
    const setDetail = detail.map((x, index) => ({
      role: x.roleId,
      menuNo: MenuNo,
      subMenuNo: SubMenuNo,
      actionNo: ActionNo,
      status: x.selected === true ? "Y" : "N",
    }));

    const body = { detail: setDetail };

    try {
      const result = await api
        .post("api/setting/configactionmenu/add", body)
        .then((res) => {
          if (res) {
            setOpen(true);
            setmessage(res.data.message);
            setTimeout(() => {
              handleCloseDialog();
              setOpen(false);
              setLoading(false);
              window.location.reload(false);
            }, 2000);
            // fetchActionRole();
          }
        });
    } catch (error) {
      console.log("error => ", error);
      setLoading(false);
    }
  };

  const setSelectedRole = () => {
    const roles = ActionRole.filter((x) => x.actionNo === ActionNo);
    const roleIds = roles.map((x) => x.role);

    const roleList = [...RoleList]; // clone deep
    // RoleList[0].selected = 0

    for (let r of roleList) {
      r.selected = roleIds.indexOf(r.roleId) > -1 ? true : false;
    }

    setRoleList([...roleList]);

    // setRoleList(RoleList.map((x) => ({ ...x, selected: true })));
  };

  useEffect(() => {
    async function fetchData() {
      await fetchMenuAll();
      await fetchActionRole();
      if (MenuNo || SubMenuNo) {
        await fetchActionMenu();
      }
    }
    fetchData();
  }, [SubMenuNo]);

  useEffect(() => {
    async function fetchData() {
      await fetchActionRole();
      if (ActionNo) {
        await setSelectedRole();
      }
    }
    fetchData();
  }, [ActionNo]);

  useEffect(() => {
    async function fetchData() {
      await fetchRoleList();
    }
    fetchData();
  }, []);

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenCancel(false);
    setName("");
    setMenuNo("");
    setSubMenuNo("");
    setStatus("Y");
    setLoading(false);
  };

  const handleCloseDialog = (props) => {
    setOpen(props);
  };

  const handleSaveEditAction = () => {
    const selectedAction = ActionList.filter((x) => x.actionNo === ActionNo);

    selectedAction.forEach((x) => {
      x.actionName = Name;
    });

    setActionList([...ActionList]);

    EditAndAddAction();
  };

  const handleCancelAction = (ActionSelect) => {
    //need Data
    setMenuNo(MenuNo);
    setSubMenuNo(ActionSelect.subMenuNo);
    setActionNo(ActionSelect.actionNo);
    setStatus("N");

    setOpenCancel(true);
  };

  const handleEditAndAddAction = (Data) => {
    //need Data
    setMenuNo(MenuNo);
    setSubMenuNo(Data.subMenuNo);
    setName(Data.actionName);
    setActionNo(Data.actionNo);
    setOpenEdit(true);
  };

  const handleClickSubForAction = (Data) => {
    setSubMenuNo(Data.subMenuNo);
    setMenuNo(Data.menuNo);
  };

  const handleCheckBox = (role, index, checked) => {
    const _roleList = RoleList.map((item, index2) =>
      index === index2 ? { ...item, selected: checked } : item
    );

    setRoleList(_roleList);
    setDetail(_roleList);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={1} style={{ height: "88vh", overflow: "auto" }}>
        <div className={classes.Padding}>
          <p style={{ color: "#FF0000", fontSize: 18 }}>B-Admin</p>
          <h3>กำหนดสิทธิ์</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginBottom: 30,
              position: "sticky",
              top: 10,
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{
                color: "white",
                backgroundColor: "#FF0000",
                borderColor: "transparent",
                marginLeft: 10,
                width: 120,
                textTransform: "lowercase",
              }}
              onClick={() => saveRole()}
            >
              {Loading ? (
                <CircularProgress
                  sx={{
                    color: "#FFFFFF",
                  }}
                  size={24}
                />
              ) : (
                "บันทึก"
              )}
            </Button>
          </div>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <p style={{ color: "#FF0000" }}>Category</p>
              <div
                style={{ maxHeight: "58vh", height: "100%", overflow: "auto" }}
              >
                <table style={{ width: "100%", maxWidth: "100%" }}>
                  {MenuList.map((Data, index) => (
                    <tbody>
                      <tr key={index}>
                        <td
                          style={{
                            display: "flex",
                            border: "1px solid #e0e0e0",
                            flexWrap: "wrap",
                          }}
                          onClick={() => setMenuNo(Data.menuNo)}
                        >
                          <span
                            style={{
                              margin: "5px 0px 5px 5px",
                            }}
                          >
                            {Data.menuName}
                          </span>
                        </td>
                        {MenuList.find(
                          (x) => x.menuNo === Data.menuNo
                        ).subMenu.map((sub) => (
                          <td
                            className="table-list-group-item-action"
                            style={
                              sub.subMenuNo === SubMenuNo
                                ? {
                                    display: "flex",
                                    border: "1px solid #e0e0e0",
                                    backgroundColor: "#e7e7e7",
                                  }
                                : {
                                    display: "flex",
                                    border: "1px solid #e0e0e0",
                                    // backgroundColor: "#FAFBFD",
                                  }
                            }
                            onClick={() => handleClickSubForAction(sub)}
                          >
                            <span style={{ margin: "5px 0px 5px 20px" }}>
                              {sub.subMenuName}
                            </span>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </Grid>

            <Grid item xs={4}>
              <p style={{ color: "#FF0000" }}>Action</p>
              <div style={{ maxHeight: "58vh", height: "58vh" }}>
                <table style={{ width: "100%", maxWidth: "100%" }}>
                  {MenuList.find((x) => x.menuNo === MenuNo)
                    ?.subMenu.filter((sub) => sub.subMenuNo === SubMenuNo)[0]
                    ?.actionMenu?.map((Data, index) => (
                      // ActionList.map((Data, index) => (
                      <tbody>
                        <tr>
                          <td
                            className="table-list-group-item-action"
                            key={Data.actionNo}
                            style={
                              Data.actionNo === ActionNo
                                ? {
                                    display: "flex",
                                    border: "1px solid #e0e0e0",
                                    flexWrap: "wrap",
                                    backgroundColor: "#e7e7e7",
                                  }
                                : {
                                    display: "flex",
                                    border: "1px solid #e0e0e0",
                                    flexWrap: "wrap",
                                  }
                            }
                            onClick={() => setActionNo(Data.actionNo)}
                          >
                            <span
                              style={{
                                margin: "5px 0px 5px 5px",
                              }}
                            >
                              [{Data.actionNo}]<span>&nbsp;</span>
                              {Data.actionName}
                            </span>
                            {/* <div
                              style={{
                                justifyContent: "flex-end",
                                display: "flex",
                                flexGrow: 1,
                              }}
                            >
                              <button
                                style={{
                                  border: 0,
                                  backgroundColor: "transparent",
                                }}
                              >
                                <span
                                  className="material-icons"
                                  onClick={() => handleEditAndAddAction(Data)}
                                >
                                  edit
                                </span>
                              </button>
                              <button
                                style={{
                                  border: 0,
                                  backgroundColor: "transparent",
                                }}
                              >
                                <span
                                  className="material-icons-outlined"
                                  style={{ color: "#FF0000" }}
                                  onClick={() => handleCancelAction(Data)}
                                >
                                  close
                                </span>
                              </button>
                            </div> */}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </Grid>

            <Grid item xs={4}>
              <p style={{ color: "#FF0000" }}>Role</p>
              <div style={{ maxHeight: "58vh", height: "58vh" }}>
                <table style={{ width: "100%", maxWidth: "100%" }}>
                  {ActionNo &&
                    RoleList.map((Data, index) => (
                      <tbody>
                        <tr>
                          <td
                            key={index}
                            style={{
                              display: "flex",
                              border: "1px solid #e0e0e0",
                              flexWrap: "wrap",
                            }}
                            // onClick={() => setActionNo(Data.actionNo)}
                          >
                            <Checkbox
                              size="small"
                              sx={{
                                color: red[500],
                                "&.Mui-checked": {
                                  color: red[500],
                                },
                              }}
                              id={"Role" + index}
                              name={"Role" + index}
                              onChange={(e) =>
                                handleCheckBox(Data, index, e.target.checked)
                              }
                              checked={Data?.selected || false}
                            />
                            <span
                              style={{
                                margin: "5px 0px 5px 5px",
                              }}
                            >
                              {Data.roleName}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </Grid>
          </Grid>

          <Dialog
            open={OpenEdit}
            onClose={() => handleClose()}
            fullWidth={true}
            maxWidth="xs"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogContent>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                onChange={(e) => setName(e.target.value)}
                value={Name}
                required
                // error={ExcelNameErr}
                label="ชื่อ"
              />
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "black",
                  borderColor: "transparent",
                  backgroundColor: "#F8F9FA",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button>

              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#FF0000",
                  borderColor: "transparent",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => handleSaveEditAction()}
              >
                {Loading ? (
                  <CircularProgress
                    sx={{
                      color: "#FFFFFF",
                    }}
                    size={24}
                  />
                ) : (
                  "ตกลง"
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openCancel}
            onClose={() => handleClose()}
            fullWidth={true}
            maxWidth="xs"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle>ยืนยันที่จะลบหรือไม่</DialogTitle>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "black",
                  borderColor: "transparent",
                  backgroundColor: "#F8F9FA",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#FF0000",
                  borderColor: "transparent",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => EditAndAddAction("del")}
              >
                {Loading ? (
                  <CircularProgress
                    sx={{
                      color: "#FFFFFF",
                    }}
                    size={24}
                  />
                ) : (
                  "ตกลง"
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <DialogForAPI
            DialogOpen={open}
            DialogClose={handleCloseDialog}
            message={message}
          />
        </div>
      </Paper>
    </div>
  );
}
