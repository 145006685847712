import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import store from "./redux/store";
import { Provider, useSelector } from "react-redux";
import envInstants from "./libs/configs/env";
import httpClientInstants from "./libs/utils/HttpClient";
import api, { setDefaultURL, setInterceptors } from "./Component/api/api";
import { useHistory } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Router from "./Router";

function App(props) {
  const [isInitEnvError, setInitEnvError] = useState(false);
  const [isInitEnv, setInitEnv] = useState(false);
  const token = localStorage.getItem("token");

  const history = useHistory();
  useEffect(() => {
    const loadEnv = async () => {
      try {
        await envInstants.init();
        httpClientInstants.setBaseUrl(envInstants.getConfig().baseUrl);
        setDefaultURL(envInstants.getConfig().baseUrl);
        // const userRes = await api.post("/api/users/user");
        // const roleRes = await api.get(
        //   `/api/setting/actions/role?Role=${userRes.data.results.roleId}`
        // );

        // console.log(api.defaults.baseURL);
      } catch (error) {
        setInitEnvError(true);
        history.push("/login");
      } finally {
        setInitEnv(true);
      }
    };

    loadEnv();
  }, []);

  if (!isInitEnv) return "Loading...";
  if (isInitEnvError) return "Cannot load env !!!";

  const THEME = createTheme({
    typography: {
      fontFamily: `Kanit`,
    },
  });


  return (
    <Suspense>
      <ThemeProvider theme={THEME}>
        <Provider store={store}>
          <Router />
        </Provider>
      </ThemeProvider>
    </Suspense>
  );
}

export default App;
