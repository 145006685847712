import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from "@reduxjs/toolkit";
// import {
//   FLUSH,
//   PAUSE,
//   PERSIST,
//   persistReducer,
//   persistStore,
//   PURGE,
//   REGISTER,
//   REHYDRATE
// } from "redux-persist";
import globalSlice from "./modules/globalSlice";
import actionSlice from "./modules/actionSlice";
import userSlice from "./modules/UserSlice";

const rootReducer = combineReducers({
  global: globalSlice,
  action: actionSlice,
  user: userSlice,
});

const store = configureStore({
  reducer: rootReducer,
  // middleware: getDefaultMiddleware({
  //   serializableCheck: {
  //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //   },
  // }),
});

// export const persistor = persistStore(store);

export default store;
