import React, { useState, useEffect } from "react";
import {
  Paper,
  InputBase,
  Button,
  TablePagination,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory, Link } from "react-router-dom";
import api from "../../Component/api/api";
import qs from "qs";
import Grid from "@mui/material/Grid";
import moment from "moment";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  search: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0e0e0",
    width: "100%",
    marginRight: 20,
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    // backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function Ticket() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");

  const [keyword, setKeyword] = useState("");
  const [TicketList, setTicketList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const isBackend = true;
  const [Status, setStatus] = useState("");
  const [TopicList, setTopicList] = useState([]);
  const [TopicId, setTopicId] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchTicketList = async () => {
    try {
      const params = qs.stringify({
        ...(keyword && { keyword }),
        ...(isBackend && { isBackend }),
        ...(TopicId && { TopicId }),
        ...(Status && { Status }),
      });

      const result = await api.get(`/api/bvoice/head/list?${params}`);
      const _result = result.data.results;
      setTicketList(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const fetchTopicList = async () => {
    try {
      await api.get("/api/bvoice/topic/list").then((res) => {
        if (res) {
          const _result = res.data.results;
          setTopicList(_result);
        }
      });
    } catch (error) {
      console.log("error => ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchTicketList();
    } else {
      history.push("/login");
    }
  }, [keyword, TopicId, Status]);

  useEffect(() => {
    fetchTopicList();
  }, []);

  return (
    <div className={classes.root}>
      <Paper elevation={1} style={{ height: "88vh" }}>
        <div class={classes.Padding}>
          <p style={{ color: "red" }}>B-Voice</p>
          <h3>B-Voice</h3>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: { sm: "right" }, marginBottom: 2 }}
            rowSpacing={1}
          >
            <Grid item xs={7} sm={4} lg={2}>
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <Select
                  style={{ marginTop: 5 }}
                  value={Status}
                  onChange={(e) => setStatus(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  disableUnderline
                >
                  <MenuItem value="">
                    <em>สถานะ</em>
                  </MenuItem>
                  <MenuItem value="A">Active</MenuItem>
                  <MenuItem value="C">Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={7} sm={4} lg={2}>
              <FormControl
                size="small"
                sx={{
                  width: "100%",
                }}
              >
                <Select
                  style={{ marginTop: 5 }}
                  value={TopicId}
                  onChange={(e) => setTopicId(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  disableUnderline
                >
                  <MenuItem value="">
                    <em>หัวเรื่อง</em>
                  </MenuItem>
                  {TopicList.map((Data) => (
                    <MenuItem value={Data.topicId}>{Data.topicName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={7} sm={4} lg={3}>
              <div className={classes.search}>
                <SearchIcon style={{ margin: 10 }} />
                <InputBase
                  multiline
                  fullWidth
                  placeholder="ค้นหา"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>

          <TableContainer sx={{ maxHeight: "59vh", minHeight: "59vh" }}>
            <Table stickyHeader size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width="20%" align="center">
                    เลขตั๋ว
                  </StyledTableCell>
                  <StyledTableCell width="35%" align="center">
                    หัวเรื่อง
                  </StyledTableCell>
                  <StyledTableCell width="15%" align="center">
                    วันที่สร้าง
                  </StyledTableCell>
                  <StyledTableCell width="15%" align="center">
                    วันที่แก้ไข
                  </StyledTableCell>
                  <StyledTableCell width="15%" align="center">
                    สถานะ
                  </StyledTableCell>
                  <StyledTableCell width="15%" align="center">
                    แก้ไข
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? TicketList.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                  : TicketList
                )
                  // .filter((i, index) => index < 100)
                  .map((Data, index) => {
                    return (
                      <StyledTableRow key={Data.voiceId}>
                        <StyledTableCell align="center">
                          {Data.voiceNo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {Data.topicName}
                          {Data.topicSubName !== "" ? " | " : null}
                          {Data.topicSubName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.createDate
                            ? moment(Data.createDate).format("YYYY-MM-DD")
                            : " "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.modifiedDate
                            ? moment(Data.modifiedDate).format("YYYY-MM-DD")
                            : " "}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          sx={
                            Data.status === "A"
                              ? { color: "#00A88F" }
                              : { color: "#FF0000" }
                          }
                        >
                          {Data.status === "A" ? "Active" : "Closed"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link
                            to={{
                              pathname: `/TicketDetail/${Data.voiceId}`,
                              state: { HeadData: Data },
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "#FF0000",
                                borderColor: "transparent",
                                marginRight: 10,
                                width: 80,
                              }}
                            >
                              เพิ่มเติม
                            </Button>
                          </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 45, 105]}
            component="div"
            count={TicketList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  );
}
