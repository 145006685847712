import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Component/api/api";
import LoadingAdapter from "../../libs/utils/LoadingAdapter";

let initialState = { user: [] };

export const getUserDetail = createAsyncThunk(
  "UserDetail/getUserDetail", // ตัวแรก ต้องตรวกับ name
  async () => {
    const result = await api.post("/api/users/user");

    const _result = result.data.results;
    return _result;
  }
);

const userSlice = createSlice({
  name: "UserDetail",
  initialState: initialState,
  reducers: {
    // ไม้ต่อ api
  },
  extraReducers: (builder) => {
    // ต่อ api
    LoadingAdapter.applyAsyncBuilder(getUserDetail, builder, ["user"]);
  },
});

export default userSlice.reducer;
