import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../../Component/api/api";
import LoadingAdapter from "../../libs/utils/LoadingAdapter";

const token = localStorage.getItem("token");
let initialState = { list: [] };

export const getActions = createAsyncThunk(
  "action/getActions", // ตัวแรก ต้องตรวกับ name
  async (props) => {
    var _token = props ? props : token;
    if (_token) {
      const roleRes = await api.post("/api/users/actions/select");
      const _roleList = roleRes.data.results;
      return _roleList;
    }
  }
);

const actionSlice = createSlice({
  name: "action",
  initialState: initialState,
  reducers: {
    // ไม้ต่อ api
  },
  extraReducers: (builder) => {
    // ต่อ api
    LoadingAdapter.applyAsyncBuilder(getActions, builder, ["list"]);
  },
});

export default actionSlice.reducer;
