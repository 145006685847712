import React, { useState, useEffect } from "react";
import {
  Paper,
  InputBase,
  Button,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextareaAutosize,
  CircularProgress,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useHistory, Link } from "react-router-dom";
import qs from "qs";
import api from "../../Component/api/api";
import moment from "moment";
import DialogForAPI from "../../Component/DialogForAPI";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  search: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0e0e0",
    width: "100%",
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
  dialogPaper: {
    height: "450px",
  },
  dialogPaper2: {
    height: "90vh",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    // backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function OrderList() {
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem("token");

  const [OrderList, setOrderList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [openAdd, setOpenAdd] = useState(false);
  const [Remark, setRemark] = useState(null);
  const [saleNo, setSaleNo] = useState(null);
  const [Name, setName] = useState(null);
  const [Org, setOrg] = useState(null);
  const [item, setItem] = useState(null);
  const [SalesHeadId, setSalesHeadId] = useState(null);
  const [ItemId, setItemId] = useState(null);
  const [Qty, setQty] = useState(null);
  const [open, setOpen] = useState(false);

  const [LoadingC, setLoadingC] = useState(false);
  const [LoadingN, setLoadingN] = useState(false);
  const [isPending, setisPending] = useState(true);
  const [isDelivery, setisDelivery] = useState(false);
  const [isCancel, setisCancel] = useState(false);
  const [StatusCancel, setStatusCancel] = useState("");
  const [DetailforDialog, setDetailforDialog] = useState([]);
  const [message, setmessage] = useState("");

  const fetchOrderList = async () => {
    try {
      const params = qs.stringify({
        isBackend: true,
        ...(keyword && { keyword }),
        ...(isPending && { isPending }),
        ...(isDelivery && { isDelivery }),
        ...(isCancel && { isCancel }),
      });

      const result = await api.get(`/api/bpoint/salehead/list?${params}`);
      const _result = result.data.results;
      setOrderList(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };
  const upDateSale = async () => {
    setLoadingN(true);
    const body = {
      SalesHeadId,
      Status: "D",
      Remark: !Remark ? null : Remark,
      ItemId,
      Qty,
    };
    try {
      const result = await api
        .post("/api/bpoint/salehead/update", body)
        .then((res) => {
          if (res) {
            setOpen(true);
            setmessage(res.data.message);
            setTimeout(() => {
              handleClose();
              setOpen(false);
            }, 2000);
            fetchOrderList();
          }
        });
    } catch (error) {
      console.log("error => ", error);
      setLoadingN(false);
    }
  };
  const cancelSale = async () => {
    setLoadingC(true);
    const body = {
      SalesHeadId,
      Status: "N",
      Remark: !Remark ? null : Remark,
      ItemId,
      Qty,
    };
    try {
      const result = await api.post("/api/bpoint/salehead/update", body);
      setOpen(true);
      setTimeout(() => {
        handleClose();
      }, 2000);
      fetchOrderList();
    } catch (error) {
      console.log("error => ", error);
    }
  };

  useEffect(() => {
    if (token) {
      fetchOrderList();
    } else {
      history.push("/login");
    }
  }, [keyword, isPending, isDelivery, isCancel]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleEdit = (results) => {
    setRemark(results.remark);
    setSaleNo(results.salesheadNo);
    setSalesHeadId(results.salesHeadId);
    setItemId(results.itemId);
    setQty(results.qty);
    setOpenAdd(true);
    setName(results.firstname + " " + results.lastname);
    setItem(results.itemName);
    setOrg(results.orgname);
    setStatusCancel(results.status);
    setDetailforDialog(results.logSale);
  };

  const handleClose = () => {
    setOpenAdd(false);
    setSaleNo("");
    setSalesHeadId("");
    setItemId("");
    setQty("");
    setRemark("");
    setLoadingC(false);
    setLoadingN(false);
  };

  const handleCloseDialog = (props) => {
    setOpen(props);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={1} style={{ height: "88vh" }}>
        <div class={classes.Padding}>
          <p style={{ color: "red" }}>B-Reward</p>
          <h3>รายการของที่สั่งทั้งหมด</h3>
          <Grid
            container
            spacing={2}
            sx={{
              // justifyContent: { sm: "right" },
              margin: "20px 0px 20px 0px",
            }}
          >
            <Grid item xs={12} sm={8} lg={9}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#FF0000",
                      "&.Mui-checked": {
                        color: "#FF0000",
                      },
                    }}
                    id={"Active"}
                    checked={isPending}
                    onChange={(e) => setisPending(e.target.checked)}
                  />
                }
                label="รอจัดส่ง"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#FF0000",
                      "&.Mui-checked": {
                        color: "#FF0000",
                      },
                    }}
                    id={"Active"}
                    checked={isDelivery}
                    onChange={(e) => setisDelivery(e.target.checked)}
                  />
                }
                label="ส่งแล้ว"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#FF0000",
                      "&.Mui-checked": {
                        color: "#FF0000",
                      },
                    }}
                    id={"Active"}
                    checked={isCancel}
                    onChange={(e) => setisCancel(e.target.checked)}
                  />
                }
                label="ยกเลิก"
              />
            </Grid>
            <Grid item xs={7} sm={4} lg={3}>
              <div className={classes.search}>
                <SearchIcon style={{ margin: 10 }} />
                <InputBase
                  multiline
                  fullWidth
                  placeholder="ค้นหา"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>

          <TableContainer sx={{ maxHeight: "58vh", height: "57vh" }}>
            <Table stickyHeader size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">วันที่</StyledTableCell>
                  <StyledTableCell align="center">เลขที่</StyledTableCell>
                  <StyledTableCell align="center">ชื่อ-สกุล</StyledTableCell>
                  <StyledTableCell align="center">แผนก</StyledTableCell>
                  <StyledTableCell align="center">สินค้า</StyledTableCell>
                  <StyledTableCell align="center">คะแนน</StyledTableCell>
                  <StyledTableCell align="center">จำนวน</StyledTableCell>
                  <StyledTableCell align="center">วันที่แก้ไข</StyledTableCell>
                  <StyledTableCell align="center">สถานะ</StyledTableCell>
                  <StyledTableCell align="center">แก้ไข</StyledTableCell>
                </TableRow>
              </TableHead>
              {OrderList.length > 0 ? (
                <TableBody>
                  {(rowsPerPage > 0
                    ? OrderList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : OrderList
                  ).map((Data, index) => {
                    return (
                      <StyledTableRow key={Data.salesHeadId}>
                        <StyledTableCell align="center">
                          {moment(Data.salesDate).format("DD-MM-YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.salesheadNo}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {Data.firstname}
                          <span>&nbsp;&nbsp;</span>
                          {Data.lastname}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.orgname}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.itemName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.totalPoint}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.qty}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {Data.modifiedDate
                            ? moment(Data.modifiedDate).format("DD-MM-YYYY")
                            : null}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <span
                            style={
                              Data.statusName === "ส่งแล้ว"
                                ? { color: "#00A88F" }
                                : Data.statusName === "ยกเลิก"
                                ? { color: "#FF0000" }
                                : { color: "black" }
                            }
                          >
                            {Data.statusName}
                          </span>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            onClick={() => handleEdit(Data)}
                            variant="contained"
                            style={{
                              color: "white",
                              backgroundColor: "#FF0000",
                              borderColor: "transparent",
                              marginRight: 10,
                              width: 80,
                            }}
                          >
                            เพิ่มเติม
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <Dialog
            open={openAdd}
            onClose={handleClose}
            fullWidth={true}
            maxWidth="sm"
            classes={{ paper: classes.dialogPaper2 }}
          >
            <DialogTitle>
              <h4>
                เลขที่{" "}
                <span style={{ color: "red", marginLeft: 10 }}>{saleNo}</span>
              </h4>
              <h4>
                ชื่อ{" "}
                <span style={{ color: "red", marginLeft: 10 }}>{Name}</span>
              </h4>
              <h4>
                แผนก <span style={{ color: "red", marginLeft: 10 }}>{Org}</span>
              </h4>
              <h4>
                สินค้า{" "}
                <span style={{ color: "red", marginLeft: 10 }}>{item}</span>
              </h4>
            </DialogTitle>
            <DialogContent>
              <div className={classes.Row}>
                <p>ตอบกลับ</p>

                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                  }}
                >
                  <TextareaAutosize
                    aria-label="minimum height"
                    maxRows={4}
                    minRows={4}
                    placeholder="..."
                    style={{ flexGrow: 1, borderColor: "#dbdbdb" }}
                    onChange={(e) => setRemark(e.target.value)}
                    value={Remark}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  maxHeight: "40vh",
                  overflow: "auto",
                  marginTop: 30,
                }}
              >
                {DetailforDialog.length > 0 && (
                  <table
                    style={{
                      width: "100%",
                      textAlign: "center",
                      border: "1px solid #e0e0e0",
                    }}
                  >
                    <thead
                      style={{
                        borderBottom: "1px solid #e0e0e0",
                        backgroundColor: "#FAFBFD",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                      }}
                    >
                      <tr>
                        <th>วันที่</th>
                        <th>สถานะ</th>
                        <th>แก้ไขล่าสุดโดย</th>
                      </tr>
                    </thead>
                    {DetailforDialog?.map((data, index) => {
                      return (
                        <tbody>
                          <tr>
                            <td>
                              {moment(data.logDate).format("DD-MM-YYYY H:mm")}
                            </td>
                            <td>
                              <span
                                style={
                                  data.statusName === "ส่งแล้ว"
                                    ? { color: "#00A88F" }
                                    : data.statusName === "ยกเลิก"
                                    ? { color: "#FF0000" }
                                    : { color: "black" }
                                }
                              >
                                {data.statusName}
                              </span>
                            </td>
                            <td>{data.firstname + " " + data.lastname}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                  </table>
                )}
              </div>
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "black",
                  borderColor: "transparent",
                  backgroundColor: "#F8F9FA",
                  width: 80,
                }}
                onClick={handleClose}
              >
                ปิด
              </Button>
              {StatusCancel !== "N" && (
                <>
                  <Button
                    variant="outlined"
                    style={{
                      color: "#FF0000",
                      borderColor: "#FF0000",
                      width: 80,
                    }}
                    onClick={() => cancelSale()}
                  >
                    {LoadingC ? (
                      <CircularProgress
                        sx={{
                          color: "#FF0000",
                        }}
                        size={24}
                      />
                    ) : (
                      "ยกเลิก"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      color: "white",
                      backgroundColor: "#FF0000",
                      borderColor: "transparent",
                      marginRight: 10,
                      width: 80,
                    }}
                    onClick={() => upDateSale()}
                  >
                    {LoadingN ? (
                      <CircularProgress
                        sx={{
                          color: "white",
                        }}
                        size={24}
                      />
                    ) : (
                      "จัดส่ง"
                    )}
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
          <TablePagination
            rowsPerPageOptions={[15, 45, 105]}
            component="div"
            count={OrderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <DialogForAPI
            DialogOpen={open}
            DialogClose={handleCloseDialog}
            message={message}
          />
        </div>
      </Paper>
    </div>
  );
}
