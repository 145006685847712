import React, { useState, useEffect } from "react";
import "./css/styles.css";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  toggle360Sidebar,
  toggleAdminSidebar,
  toggleConnectSidebar,
  toggleNewsSidebar,
  toggleRewardSidebar,
  togglePointSidebar,
  toggleReportSidebar,
  toggleVoiceSidebar,
} from "../redux/modules/globalSlice";
import api from "../Component/api/api";

const drawerHeight = "100%";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    backgroundColor: "#AFF",
  },
}));
export default function Index(props) {
  const classes = useStyles();
  const {
    sidebarOpen,
    sidebarNewsOpen,
    sidebarRewardOpen,
    sidebar360Open,
    sidebarConnectOpen,
    sidebarPointOpen,
    sidebarReportOpen,
    sidebarVoiceOpen,
    sidebarAdminOpen,
  } = useSelector((state) => state.global.sidebar);
  const dispatch = useDispatch();

  const handleDrawerClose = () => {
    props.onDrawerClose();
  };

  const [ActionNoList, setActionNoList] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const loadAction = async () => {
      try {
        if (token) {
          const roleRes = await api.post("/api/users/actions/select");
          const _roleList = roleRes.data.results.map((x) => x.actionNo);
          setActionNoList(_roleList);
        }
      } catch (error) {
        console.log(error);
      }
    };

    loadAction();
  }, []);

  return (
    <Drawer
      open={sidebarOpen}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        // "& .MuiDrawer-paper": {
        //   width: drawerWidth,
        //   boxSizing: "border-box",
        // },
      }}
      variant="permanent"
      anchor="left"
    >
      <div
      // className="sideNav"
      >
        {/* Core theme CSS (includes Bootstrap)*/}
        <div className="d-flex" id="wrapper">
          {/* Sidebar*/}
          <div className="border-end bg-white" id="sidebar-wrapper">
            <div className="sidebar-heading border-bottom bg-light">
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src="/images/logo.png"
                  alt="logoSidebar"
                  id="logoSidebar"
                  className="logoSize"
                />
              </div>

              {/* <span
                id="sidebarToggle"
                className="material-icons-outlined btn"
                onClick={handleDrawerClose}
              >
                menu
              </span> */}
            </div>
            <div className="list-group list-group-flush">
              {ActionNoList.find((x) => x === "A004" || x === "A003") && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  onClick={() => dispatch(toggleNewsSidebar(!sidebarNewsOpen))}
                >
                  <span
                    style={{ marginRight: 10 }}
                    className="material-icons-outlined"
                  >
                    campaign
                  </span>
                  News/Announcement
                  {sidebarNewsOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}

              {sidebarNewsOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A004") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3 SubMenu"
                      // exact={true}
                      activeClassName="is-active"
                      to="/AllSubject"
                    >
                      เรื่องทั้งหมด
                    </NavLink>
                  )}

                  {ActionNoList.find((x) => x === "A003") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3 SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/Information"
                    >
                      เขียนเรื่องใหม่
                    </NavLink>
                  )}
                </div>
              ) : null}

              {ActionNoList.find(
                (x) => x === "A005" || x === "A006" || x === "A007"
              ) && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  // onClick={() => toggleReward(!OpenReward)}
                  onClick={() =>
                    dispatch(toggleRewardSidebar(!sidebarRewardOpen))
                  }
                >
                  <span
                    style={{ marginRight: 10 }}
                    className="material-icons-outlined"
                  >
                    emoji_events
                  </span>
                  B-Reward
                  {sidebarRewardOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebarRewardOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A005") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/Order"
                    >
                      รายการของที่สั่ง
                    </NavLink>
                  )}
                  {ActionNoList.find((x) => x === "A006") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/AllReward"
                    >
                      ของรางวัลทั้งหมด
                    </NavLink>
                  )}
                  {ActionNoList.find((x) => x === "A007") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/AddNewReward"
                    >
                      เพิ่มของรางวัลใหม่
                    </NavLink>
                  )}
                </div>
              ) : null}

              {ActionNoList.find((x) => x === "A008") && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  // onClick={() => toggle360(!Open360)}
                  onClick={() => dispatch(toggle360Sidebar(!sidebar360Open))}
                >
                  <span
                    style={{ marginRight: 10 }}
                    className="material-icons-outlined"
                  >
                    face_retouching_natural
                  </span>
                  B-360
                  {sidebar360Open ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebar360Open ? (
                <div>
                  {ActionNoList.find((x) => x === "A008") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/RoundList"
                    >
                      พนักงานดีเด่น
                    </NavLink>
                  )}

                  {/* <NavLink
                    className="list-group-item list-group-item-action list-group-item-light p-3"
                    exact={true}
                    activeClassName="is-active"
                    to="/AddMember"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    เพิ่มพนักงานดีเด่น
                  </NavLink> */}
                </div>
              ) : null}

              {ActionNoList.find((x) => x === "A009" || x === "A010") && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  onClick={() =>
                    dispatch(toggleConnectSidebar(!sidebarConnectOpen))
                  }
                >
                  <span
                    style={{ marginRight: 10 }}
                    className="material-icons-outlined"
                  >
                    add_link
                  </span>
                  B-Connect
                  {sidebarConnectOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebarConnectOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A009") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/AllLink"
                    >
                      ลิงค์ทั้งหมด
                    </NavLink>
                  )}

                  {ActionNoList.find((x) => x === "A010") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/AddLink"
                    >
                      เพิ่มลิงค์ใหม่
                    </NavLink>
                  )}
                </div>
              ) : null}

              {ActionNoList.find((x) => x === "A011") && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  onClick={() =>
                    dispatch(togglePointSidebar(!sidebarPointOpen))
                  }
                >
                  <span
                    className="material-icons-outlined"
                    style={{ marginRight: 10 }}
                  >
                    sentiment_dissatisfied
                  </span>
                  B-Point
                  {sidebarPointOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebarPointOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A011") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/AllPoint"
                    >
                      คะแนนทั้งหมด
                    </NavLink>
                  )}
                </div>
              ) : null}

              {ActionNoList.find((x) => x === "A012") && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  onClick={() =>
                    dispatch(toggleReportSidebar(!sidebarReportOpen))
                  }
                >
                  <span
                    className="material-icons-outlined"
                    style={{ marginRight: 10 }}
                  >
                    summarize
                  </span>
                  B-Report
                  {sidebarReportOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebarReportOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A012") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/Report"
                    >
                      รายงาน
                    </NavLink>
                  )}
                </div>
              ) : null}

              {ActionNoList.find((x) => x === "A025" || x === "A026") && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  // onClick={() => toggleAdmin(!OpenAdmin)}
                  onClick={() =>
                    dispatch(toggleVoiceSidebar(!sidebarVoiceOpen))
                  }
                >
                  <span
                    style={{ marginRight: 10 }}
                    className="material-icons-outlined"
                  >
                    contact_support
                  </span>
                  B-Voice
                  {sidebarVoiceOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebarVoiceOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A025") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/Ticket"
                    >
                      B-voice
                    </NavLink>
                  )}
                  {ActionNoList.find((x) => x === "A026") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/ConfigurationTopic"
                    >
                      กำหนดสิทธิ์หัวเรื่อง
                    </NavLink>
                  )}
                </div>
              ) : null}

              {ActionNoList.find(
                (x) =>
                  x === "A013" || x === "A015" || x === "A024" || x === "A014"
              ) && (
                <a
                  style={{ display: "flex", flexDirection: "row" }}
                  className="list-group-item list-group-item-action list-group-item-light p-3"
                  href="#!"
                  // onClick={() => toggleAdmin(!OpenAdmin)}
                  onClick={() =>
                    dispatch(toggleAdminSidebar(!sidebarAdminOpen))
                  }
                >
                  <span
                    style={{ marginRight: 10 }}
                    className="material-icons-outlined"
                  >
                    admin_panel_settings
                  </span>
                  B-Admin
                  {sidebarAdminOpen ? (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      expand_more
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "end",
                      }}
                      className="material-icons-outlined"
                    >
                      chevron_right
                    </span>
                  )}
                </a>
              )}
              {sidebarAdminOpen ? (
                <div>
                  {ActionNoList.find((x) => x === "A013") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/AllUser"
                    >
                      ผู้ใช้ทั้งหมด
                    </NavLink>
                  )}

                  {ActionNoList.find((x) => x === "A015") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3  SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/ManagePermissions"
                    >
                      จัดการสิทธิ์
                    </NavLink>
                  )}

                  {ActionNoList.find((x) => x === "A024") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3 SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/SetPermissions"
                    >
                      กำหนดสิทธิ์
                    </NavLink>
                  )}

                  {ActionNoList.find((x) => x === "A014") && (
                    <NavLink
                      className="list-group-item list-group-item-action list-group-item-light p-3 SubMenu"
                      exact={true}
                      activeClassName="is-active"
                      to="/Role"
                    >
                      บทบาท
                    </NavLink>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}
