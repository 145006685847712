import React, { useState, useEffect } from "react";
import {
  Paper,
  InputBase,
  Button,
  TablePagination,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Autocomplete,
  TextField,
  TextareaAutosize,
  DialogActions,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { alpha, styled } from "@mui/material/styles";
import { useHistory, Link } from "react-router-dom";
import qs from "qs";
import moment from "moment";
import api from "../../Component/api/api";
import "moment/locale/th";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Switch from "@mui/material/Switch";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  search: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0e0e0",
    width: "100%",
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
  Row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subject: {
    width: "30%",
  },
  widthContent: {
    marginTop: 20,
    width: 250,
  },
  dialogPaper: {
    height: "500px",
  },
  Row: {
    display: "flex",
    flexDirection: "column",
    marginTop: 10,
  },
  dialogPaperSuccess: {
    height: "450px",
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 18,
    // backgroundColor: theme.palette.action.hover,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FF0000",
    "&:hover": {
      backgroundColor: alpha("#FF0000", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FF0000",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AllRound() {
  const classes = useStyles();
  const token = localStorage.getItem("token");
  const history = useHistory();

  const [keyword, setKeyword] = useState("");
  const [RoundList, setRoundList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  moment.locale("th");

  const [isBackend, setIsBackend] = useState(true);
  const [isActive, setIsActive] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [EmpId, setEmpId] = useState("");
  const [Remark, setRemark] = useState(null);
  const [EmpList, setEmpList] = useState([]);
  const [FName, setFName] = useState("");
  const [LName, setLName] = useState("");
  const [StartDate, setStartDate] = useState(new Date());
  const [EndDate, setEndDate] = useState(new Date());
  const [Deleted, setDeleted] = useState(true);
  const [ExcellDetailId, setExcellDetailId] = useState("");
  const [EmpNo, setEmpNo] = useState("");
  const [Loading, setLoading] = useState(false);
  const [OpenSuccess, setOpenSuccess] = useState(false);
  const [Message, setMessage] = useState("");

  const fetchRoundList = async () => {
    try {
      const params = qs.stringify({
        ...(keyword && { keyword }),
        ...(isActive && { isActive }),
        ...(isBackend && { isBackend }),
      });

      const result = await api.get(`/api/excellent/head/list?${params}`);
      const _result = result.data.results;
      setRoundList(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const fetchEmpList = async () => {
    try {
      const params = qs.stringify({
        ...(keyword && { keyword }),
      });

      const result = await api.get(`/api/employee/list?${params}`);
      const _result = result.data.results;
      setEmpList(_result);
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const AddEmp = async () => {
    setLoading(true);

    const body = [
      {
        ExcellDetailId: ExcellDetailId ? ExcellDetailId : null,
        EmpId,
        Remark,
        StartDate: moment(StartDate).format("YYYY-MM-DD"),
        EndDate: moment(EndDate).format("YYYY-MM-DD"),
        Deleted: Deleted === false ? true : false,
      },
    ];
    try {
      const result = await api.post("api/excellent/detail/add", body);
      fetchRoundList();
      if (result.data.message === "Success.") {
        setMessage(result.data.message);
        setOpenSuccess(true);
      } else {
        setMessage(result.data.message);
      }
      setTimeout(() => {
        handleClose();
      }, 2000);
    } catch (error) {
      console.log("error => ", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchRoundList();
      fetchEmpList();
    } else {
      history.push("/login");
    }
  }, [keyword, isActive]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClose = () => {
    setMessage("");
    setOpenSuccess(false);
    setLoading(false);
    setOpenAdd(false);
    setOpenEdit(false);
    setEmpId("");
    setRemark("");
    setExcellDetailId("");
    setEmpNo("");
    setStartDate(new Date());
    setEndDate(new Date());
    setDeleted(true);
  };

  const handleChangeEmp = (Data) => {
    setEmpId(Data.empId);
  };

  const handleClickOpen = (EmpDetail) => {
    setExcellDetailId(EmpDetail.excellDetailId);
    setEmpId(EmpDetail.empId);
    setRemark(EmpDetail.remark);
    setStartDate(EmpDetail.startDate);
    setEndDate(EmpDetail.endDate);
    setDeleted(EmpDetail.deleted === false ? true : false);
    setEmpNo(EmpDetail.empNo);
    setOpenEdit(true);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={1} style={{ height: "88vh" }}>
        <div class={classes.Padding}>
          <p style={{ color: "red" }}>B-360</p>
          <h3>พนักงานดีเด่น</h3>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: { sm: "right" }, marginBottom: 2 }}
            rowSpacing={1}
          >
            <Grid item xs={7} sm={2.5} lg={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      color: "#FF0000",
                      "&.Mui-checked": {
                        color: "#FF0000",
                      },
                    }}
                    id={"Active"}
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                }
                label="ใช้งาน"
              />
            </Grid>
            <Grid item xs={7} sm={2.5} lg={1.5} sx={{ marginRight: { md: 2 } }}>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#FF0000",
                  borderColor: "transparent",
                  width: 140,
                }}
                onClick={() => setOpenAdd(true)}
              >
                เพิ่มพนักงานดีเด่น
              </Button>
            </Grid>
            <Dialog
              open={openAdd}
              onClose={handleClose}
              fullWidth={true}
              maxWidth="xs"
              classes={{ paper: classes.dialogPaper }}
              name="add"
              id="add"
            >
              <DialogTitle
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h3>เพิ่มพนักงาน</h3>
              </DialogTitle>
              <DialogContent>
                <div className={classes.Row}>
                  <p>ค้นหาพนักงาน</p>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Autocomplete
                        style={{ flexGrow: 1 }}
                        disablePortal
                        id="Emp"
                        sx={{ width: 350 }}
                        options={EmpList.filter(
                          ({ empId }) =>
                            !RoundList.map((x) => x.empId).includes(empId)
                        )}
                        defaultValue={EmpList.find((v) => v.empId === EmpId)}
                        getOptionLabel={(option) => {
                          //filter value
                          return `${option.empNo}: ${option.fistName} ${option.lastName}`;
                        }}
                        onChange={(e, newValue) => {
                          if (newValue) {
                            handleChangeEmp(newValue);
                          } else {
                            setEmpId("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            placeholder="เพิ่มบุคคล"
                          />
                        )}
                      />
                    </div>
                  </div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่เริ่ม"
                      inputFormat="dd-MM-yyyy"
                      value={StartDate}
                      onChange={(newValue) => {
                        setStartDate(moment(newValue).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          {...params}
                          size="medium"
                        />
                      )}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="วันที่สิ้นสุด"
                      inputFormat="dd-MM-yyyy"
                      value={EndDate}
                      onChange={(newValue) => {
                        setEndDate(moment(newValue).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size="medium" />
                      )}
                    />
                  </LocalizationProvider>
                </div>

                <div className={classes.Row}>
                  <p>เหตุผล</p>

                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                    }}
                  >
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      placeholder="..."
                      style={{
                        flexGrow: 1,
                        borderColor: "#dbdbdb",
                      }}
                      onChange={(e) => setRemark(e.target.value)}
                      value={Remark}
                    />
                  </div>
                </div>

                <Grid container sx={{ marginBottom: 5, marginTop: 2 }}>
                  <Grid item xs={12} sm={2.5} lg={2}>
                    <p>สถานะ</p>
                  </Grid>

                  <GreenSwitch
                    checked={Deleted}
                    onChange={(e) => setDeleted(e.target.checked)}
                  />
                </Grid>
              </DialogContent>
              <DialogActions
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  style={{
                    color: "black",
                    borderColor: "transparent",
                    backgroundColor: "#F8F9FA",
                    padding: 10,
                    marginRight: 10,
                    width: 80,
                  }}
                  onClick={handleClose}
                >
                  ยกเลิก
                </Button>
                <Button
                  variant="contained"
                  style={{
                    color: "white",
                    backgroundColor: "#FF0000",
                    borderColor: "transparent",
                    padding: 10,
                    marginRight: 10,
                    width: 80,
                  }}
                  onClick={() => AddEmp()}
                >
                  {Loading ? (
                    <CircularProgress
                      sx={{
                        color: "#FFFFFF",
                      }}
                      size={24}
                    />
                  ) : (
                    "ตกลง"
                  )}
                </Button>
              </DialogActions>
            </Dialog>
            <Grid item xs={7} sm={4} lg={3}>
              <div className={classes.search}>
                <SearchIcon style={{ margin: 10 }} />
                <InputBase
                  multiline
                  fullWidth
                  placeholder="ค้นหาชื่อพนักงาน"
                  onChange={(e) => setKeyword(e.target.value)}
                />
              </div>
            </Grid>
          </Grid>

          <TableContainer sx={{ maxHeight: "58vh", height: "58vh" }}>
            <Table stickyHeader size="small" aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    วันที่เริ่มต้น
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    วันที่สิ้นสุด
                  </StyledTableCell>
                  <StyledTableCell align="center">รหัสพนักงาน</StyledTableCell>
                  <StyledTableCell align="center">ชื่อ-นามสกุล</StyledTableCell>
                  <StyledTableCell align="center">แผนก</StyledTableCell>
                  <StyledTableCell align="center">หมายเหตุ</StyledTableCell>

                  <StyledTableCell align="center">แก้ไข</StyledTableCell>
                </TableRow>
              </TableHead>
              {RoundList.length > 0 ? (
                <TableBody>
                  {(rowsPerPage > 0
                    ? RoundList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : RoundList
                  ).map((Data, index) => {
                    return (
                      <StyledTableRow key={Data.contentMainId}>
                        <StyledTableCell align="center">
                          {moment(Data.startDate).format("DD-MM-YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {moment(Data.endDate).format("DD-MM-YYYY")}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.empNo}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.fistName} <span>&nbsp;&nbsp;</span>
                          {Data.lastName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.departmentName}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {Data.remark}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                        {Data.status ? "เปิด" : "ปิด"}
                      </StyledTableCell> */}
                        <StyledTableCell align="center">
                          <Button
                            variant="contained"
                            style={{
                              color: "white",
                              backgroundColor: "#FF0000",
                              borderColor: "transparent",
                              marginRight: 10,
                              width: 80,
                            }}
                            onClick={() => handleClickOpen(Data)}
                          >
                            เพิ่มเติม
                          </Button>
                        </StyledTableCell>
                        <Dialog
                          open={openEdit}
                          onClose={handleClose}
                          fullWidth={true}
                          maxWidth="xs"
                          classes={{ paper: classes.dialogPaper }}
                          name="View"
                          id="view"
                        >
                          <DialogTitle
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <h3>รายละเอียดพนักงาน</h3>
                          </DialogTitle>
                          <DialogContent>
                            <div className={classes.Row}>
                              <p>ค้นหาพนักงาน</p>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <Autocomplete
                                    style={{ flexGrow: 1 }}
                                    disablePortal
                                    id="Emp"
                                    sx={{ width: 350 }}
                                    options={EmpList.filter(
                                      ({ empId }) =>
                                        !RoundList.map((x) => x.empId).includes(
                                          empId
                                        )
                                    )}
                                    defaultValue={EmpList.find(
                                      (v) => v.empId === EmpId
                                    )}
                                    getOptionLabel={(option) => {
                                      //filter value
                                      return `${option.empNo}: ${option.fistName} ${option.lastName}`;
                                    }}
                                    onChange={(e, newValue) => {
                                      if (newValue) {
                                        handleChangeEmp(newValue);
                                      } else {
                                        setEmpId("");
                                      }
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        size="small"
                                        placeholder="เพิ่มบุคคล"
                                      />
                                    )}
                                  />
                                </div>
                              </div>
                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label="วันที่เริ่ม"
                                  inputFormat="dd-MM-yyyy"
                                  value={StartDate}
                                  onChange={(newValue) => {
                                    setStartDate(
                                      moment(newValue).format("YYYY-MM-DD")
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      sx={{ marginBottom: 2, marginTop: 2 }}
                                      {...params}
                                      size="medium"
                                    />
                                  )}
                                />
                              </LocalizationProvider>

                              <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                              >
                                <DatePicker
                                  label="วันที่สิ้นสุด"
                                  inputFormat="dd-MM-yyyy"
                                  value={EndDate}
                                  onChange={(newValue) => {
                                    setEndDate(
                                      moment(newValue).format("YYYY-MM-DD")
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} size="medium" />
                                  )}
                                />
                              </LocalizationProvider>
                            </div>

                            <div className={classes.Row}>
                              <p>เหตุผล</p>

                              <div
                                style={{
                                  display: "flex",
                                  flexGrow: 1,
                                }}
                              >
                                <TextareaAutosize
                                  aria-label="minimum height"
                                  minRows={4}
                                  placeholder="..."
                                  style={{
                                    flexGrow: 1,
                                    borderColor: "#dbdbdb",
                                  }}
                                  onChange={(e) => setRemark(e.target.value)}
                                  value={Remark}
                                />
                              </div>
                            </div>

                            <Grid
                              container
                              sx={{ marginBottom: 5, marginTop: 2 }}
                            >
                              <Grid item xs={12} sm={2.5} lg={2}>
                                <p>สถานะ</p>
                              </Grid>

                              <GreenSwitch
                                checked={Deleted}
                                onChange={(e) => setDeleted(e.target.checked)}
                              />
                            </Grid>
                          </DialogContent>
                          <DialogActions
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="contained"
                              style={{
                                color: "black",
                                borderColor: "transparent",
                                backgroundColor: "#F8F9FA",
                                padding: 10,
                                marginRight: 10,
                                width: 80,
                              }}
                              onClick={handleClose}
                            >
                              ยกเลิก
                            </Button>
                            <Button
                              variant="contained"
                              style={{
                                color: "white",
                                backgroundColor: "#FF0000",
                                borderColor: "transparent",
                                padding: 10,
                                marginRight: 10,
                                width: 80,
                              }}
                              onClick={() => AddEmp()}
                            >
                              {Loading ? (
                                <CircularProgress
                                  sx={{
                                    color: "#FFFFFF",
                                  }}
                                  size={24}
                                />
                              ) : (
                                "ตกลง"
                              )}
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[15, 45, 105]}
            component="div"
            count={RoundList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        <Dialog
          open={OpenSuccess}
          fullWidth={true}
          maxWidth="xs"
          classes={{ paper: classes.dialogPaperSuccess }}
        >
          <DialogContent
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span
              style={{ fontSize: 100, color: "#FF0000" }}
              className="material-icons-outlined"
            >
              task_alt
            </span>

            <p
              style={{
                alignItems: "center",
                fontSize: 28,
                color: "#FF0000",
              }}
            >
              {Message}
            </p>
          </DialogContent>
        </Dialog>
      </Paper>
    </div>
  );
}
