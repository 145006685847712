import {
  Paper,
  Checkbox,
  Button,
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Grid,
  DialogTitle,
  CircularProgress,
  Select,
  Autocomplete,
  InputBase,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { red } from "@mui/material/colors";
import React, { useState, useEffect } from "react";
import api from "../../Component/api/api";
import qs from "qs";
import DialogForAPI from "../../Component/DialogForAPI.js";
import "../css/styles.css";
import SearchIcon from "@mui/icons-material/Search";

const drawerHeight = "100%";
const drawerwidth = "100%";
const useStyles = makeStyles((theme) => ({
  root: {
    height: drawerHeight,
    width: drawerwidth,
    marginTop: 20,
  },
  Padding: {
    paddingTop: "2%",
    paddingRight: "6%",
    paddingLeft: "6%",
    paddingBottom: "2%",
  },
  search: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #e0e0e0",
    width: "100%",
  },
}));

export default function ConfigurationTopic() {
  const classes = useStyles();

  const [Name, setName] = useState("");
  const [OpenEdit, setOpenEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setmessage] = useState("");
  const [openCancel, setOpenCancel] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [TopicList, setTopicList] = useState([]);
  const [ConfigTopic, setConfigTopic] = useState([]);
  const [EmpList, setEmpList] = useState([]);
  const [TopicId, setTopicId] = useState("");
  const [keyword, setKeyword] = useState("");
  const [ActionNo, setActionNo] = useState("");

  const [TileList, setTileList] = useState([]);
  const [OpenAddTitle, setOpenAddTitle] = useState(false);
  const [TitleName, setTitleName] = useState("");
  const [topicSubId, settopicSubId] = useState(null);
  const [DelTopic, setDelTopic] = useState(false);
  const [DelTitle, setDelTitle] = useState(false);


  const fetchTopicList = async () => {
    try {
      await api.get("/api/bvoice/topic/list").then((res) => {
        if (res) {
          const _result = res.data.results.map((x) => ({
            ...x,
            // selected: false,
          }));
          setTopicList(_result);
        }
      });
    } catch (error) {
      console.log("error => ", error);
    }
  };

  const EditAndAddTopic = async () => {
    setLoading(true);

    const Title = [
      {
        topicSubId: topicSubId ? topicSubId : null,
        topicSubName: TitleName ? TitleName : null,
        deleted: DelTitle,
      },
    ];
    const body = {
      TopicId: TopicId ? TopicId : null,
      TopicName: Name,
      Deleted: DelTopic,
      ConfigTopic: ConfigTopic,
      topicSub: !topicSubId && !TitleName ? [] : Title,
    };

    try {
      const result = await api
        .post("api/bvoice/topic/add", body)
        .then((res) => {
          if (res) {
            setOpen(true);
            setmessage(res.data.message);
            setTimeout(() => {
              fetchTopicList();
              handleClose();
              setOpen(false);
            }, 2000);
          }
        });
    } catch (error) {
      console.log("error => ", error);
      setLoading(false);
    }
  };

  const setSelectedEmp = (EmpData) => {
    const emp = TopicList.filter((x) => x.topicId === TopicId);
    const empIds = emp[0]?.configEmp.map((x) => x.empId);
    const ConIDs = emp[0]?.configEmp.filter((x) => x.empId);

    const empList = EmpData ? EmpData : [...EmpList];

    for (let e of empList) {
      e.selected = empIds.indexOf(e.empId) > -1 ? true : false;
    }
    for (let e of empList) {
      e.ConfigTopicID =
        empIds.indexOf(e.empId) > -1
          ? ConIDs.filter((x) => x.empId === e.empId).map(
              (id) => id.configTopicID
            ) + ""
          : null;
    }

    setEmpList([...empList]);

  };

  useEffect(() => {
    async function fetchData() {
      await fetchTopicList();
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (TopicId) {
        await setSelectedEmp();
      }
    }
    fetchData();
  }, [TopicId, topicSubId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const params = qs.stringify({
          ...(keyword && { keyword }),
        });

        await api.get(`/api/employee/list?${params}`).then(async (res) => {
          if (res) {
            const _result = res.data.results;

            const filterResult = _result.filter((x) => x.roleId === "5");
            const newEmp = filterResult.map((x) => ({
              ...x,
              selected: false,
            }));

            await setEmpList(newEmp);
            setSelectedEmp(newEmp);
          }
        });
      } catch (error) {
        console.log("error => ", error);
      }
    }
    fetchData();
  }, [keyword]);

  let timer; // Timer identifier

  // Listen for `keyup` event
  const input = document.querySelector("#input-text");
  if (input) {
    input.addEventListener("keyup", (e) => {
      const text = e.target.value;

      // Clear timer
      clearTimeout(timer);

      // Wait for X ms and then process the request
      timer = setTimeout(() => {
        setKeyword(text);
      }, 500);
    });
  }

  const handleClose = () => {
    setOpen(false);
    setOpenEdit(false);
    setOpenCancel(false);
    setName("");
    setTopicId("");
    setConfigTopic([]);
    setLoading(false);
    setOpenAddTitle(false);
    setDelTitle(false);
    setDelTopic(false);
    settopicSubId("");
    setTitleName("");
    setTileList([]);
  };

  const handleCloseDialog = (props) => {
    setOpen(props);
  };

  const handleOpenEditMenu = (Data) => {
    setName(Data.topicName);
    setOpenEdit(true);
  };

  const handleCheckBox = (emp, index, checked) => {
    const _EmpList = EmpList?.map((item, index2) =>
      index === index2
        ? {
            ...item,
            selected: checked,
            Deleted: checked === true ? false : true,
          }
        : item
    );

    const Emp = _EmpList.filter((item, index2) => index === index2);
    const _emp = Emp.map((item) => ({
      ConfigTopicID: item.ConfigTopicID ? item.ConfigTopicID : null,
      EmpId: item.empId,
      Deleted: item.Deleted === true ? true : false,
    }));

    setEmpList(_EmpList);
    setConfigTopic([...ConfigTopic, _emp[0]]);
  };

  const handleCancelTopic = (Select) => {
    setTopicId(Select.topicId);
    setName(Select.topicName);
    setDelTopic(true);

    setOpenCancel(true);
  };

  const handleCancelTitle = (Select) => {
    settopicSubId(Select.topicSubId);
    setTitleName(Select.topicSubName);
    setDelTitle(true);

    setOpenCancel(true);
  };

  const handleClick = (Data) => {
    setTopicId(Data.topicId);
    setName(Data.topicName);
    setKeyword("");
    setTileList(Data?.topicSub);
    settopicSubId(null);
  };

  const handleEditTitle = (Data) => {
    setOpenAddTitle(true);
    setTitleName(Data.topicSubName);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={1} style={{ height: "88vh", overflow: "auto" }}>
        <div className={classes.Padding}>
          <p style={{ color: "#FF0000", fontSize: 18 }}>B-Voice</p>
          <h3>กำหนดสิทธิ์หัวเรื่อง</h3>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginBottom: 30,
              position: "sticky",
              top: 10,
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{
                color: "white",
                backgroundColor: "#FF0000",
                borderColor: "transparent",
                marginLeft: 10,
                width: 120,
                textTransform: "lowercase",
              }}
              onClick={() => EditAndAddTopic()}
            >
              {Loading ? (
                <CircularProgress
                  sx={{
                    color: "#FFFFFF",
                  }}
                  size={24}
                />
              ) : (
                "บันทึก"
              )}
            </Button>
          </div>

          <Grid container spacing={5}>
            <Grid item xs={4}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ color: "#FF0000" }}>Topic</p>
                <button
                  style={{
                    border: 0,
                    backgroundColor: "transparent",
                  }}
                >
                  <span
                    className="material-icons-outlined"
                    style={{
                      color: "#FF0000",
                    }}
                    onClick={() => setOpenEdit(true)}
                  >
                    add
                  </span>
                </button>
              </div>

              <div sx={{ maxHeight: "58vh", height: "58vh" }}>
                <table style={{ width: "100%", maxWidth: "100%" }}>
                  {TopicList.map((Data, index) => (
                    <tr key={Data.topicId}>
                      <td
                        className="table-list-group-item-action"
                        style={
                          Data.topicId === TopicId
                            ? {
                                display: "flex",
                                border: "1px solid #e0e0e0",
                                flexWrap: "wrap",
                                backgroundColor: "#e7e7e7",
                              }
                            : {
                                display: "flex",
                                border: "1px solid #e0e0e0",
                                flexWrap: "wrap",
                              }
                        }
                        onClick={() => handleClick(Data)}
                      >
                        <span
                          style={{
                            margin: "5px 0px 5px 5px",
                          }}
                        >
                          {Data.topicName}
                        </span>
                        <div
                          style={{
                            justifyContent: "flex-end",
                            display: "flex",
                            flexGrow: 1,
                          }}
                        >
                          <button
                            style={{
                              border: 0,
                              backgroundColor: "transparent",
                            }}
                          >
                            <span
                              className="material-icons"
                              onClick={() => handleOpenEditMenu(Data)}
                            >
                              edit
                            </span>
                          </button>

                          <button
                            style={{
                              border: 0,
                              backgroundColor: "transparent",
                            }}
                          >
                            <span
                              className="material-icons-outlined"
                              style={{
                                color: "#2DCE98",
                              }}
                              onClick={() => setOpenAddTitle(true)}
                            >
                              add
                            </span>
                          </button>

                          <button
                            style={{
                              border: 0,
                              backgroundColor: "transparent",
                            }}
                          >
                            <span
                              className="material-icons-outlined"
                              style={{ color: "#FF0000" }}
                              onClick={() => handleCancelTopic(Data)}
                            >
                              close
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </Grid>

            <Grid item xs={4}>
              <p style={{ color: "#FF0000" }}>Title</p>
              <div sx={{ maxHeight: "58vh", height: "58vh" }}>
                <table style={{ width: "100%", maxWidth: "100%" }}>
                  {TileList?.map((Data, index) => (
                    <tbody>
                      <tr>
                        <td
                          className="table-list-group-item-action"
                          key={Data.topicSubId}
                          style={
                            Data.topicSubId === topicSubId
                              ? {
                                  display: "flex",
                                  border: "1px solid #e0e0e0",
                                  flexWrap: "wrap",
                                  backgroundColor: "#e7e7e7",
                                }
                              : {
                                  display: "flex",
                                  border: "1px solid #e0e0e0",
                                  flexWrap: "wrap",
                                }
                          }
                          onClick={() => settopicSubId(Data.topicSubId)}
                        >
                          <span
                            style={{
                              margin: "5px 0px 5px 5px",
                            }}
                          >
                            {Data.topicSubName}
                          </span>
                          <div
                            style={{
                              justifyContent: "flex-end",
                              display: "flex",
                              flexGrow: 1,
                            }}
                          >
                            <button
                              style={{
                                border: 0,
                                backgroundColor: "transparent",
                              }}
                            >
                              <span
                                className="material-icons"
                                onClick={() => handleEditTitle(Data)}
                              >
                                edit
                              </span>
                            </button>
                            <button
                              style={{
                                border: 0,
                                backgroundColor: "transparent",
                              }}
                            >
                              <span
                                className="material-icons-outlined"
                                style={{ color: "#FF0000" }}
                                onClick={() => handleCancelTitle(Data)}
                              >
                                close
                              </span>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              </div>
            </Grid>

            <Grid item xs={4}>
              <p style={{ color: "#FF0000" }}>Employee</p>
              <div sx={{ maxHeight: "58vh", height: "58vh" }}>
                {TopicId && (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Grid container spacing={2} rowSpacing={1}>
                      <Grid item xs={12}>
                        <div className={classes.search}>
                          <SearchIcon style={{ margin: 10 }} />
                          <InputBase
                            id="input-text"
                            multiline
                            fullWidth
                            placeholder="ค้นหา"
                            //   onChange={(e) => setKeyword(e.target.value)}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}

                <table
                  style={{ width: "100%", maxWidth: "100%", marginTop: 20 }}
                >
                  {TopicId &&
                    EmpList?.map((Data, index) => (
                      <tbody>
                        <tr>
                          <td
                            key={index}
                            style={{
                              display: "flex",
                              border: "1px solid #e0e0e0",
                              flexWrap: "wrap",
                            }}
                            // onClick={() => setActionNo(Data.actionNo)}
                          >
                            <Checkbox
                              size="small"
                              sx={{
                                color: red[500],
                                "&.Mui-checked": {
                                  color: red[500],
                                },
                              }}
                              id={"Role" + index}
                              name={"Role" + index}
                              onChange={(e) =>
                                handleCheckBox(Data, index, e.target.checked)
                              }
                              checked={Data?.selected || false}
                            />
                            <span
                              style={{
                                margin: "5px 0px 5px 5px",
                              }}
                            >
                              {Data.fistName} <span>&nbsp;</span>
                              {Data.lastName}
                              <span>&nbsp;</span>({Data.orgname})
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                </table>
              </div>
            </Grid>
          </Grid>

          <Dialog
            open={OpenEdit}
            onClose={() => handleClose()}
            fullWidth={true}
            maxWidth="xs"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogContent>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                onChange={(e) => setName(e.target.value)}
                value={Name}
                required
                // error={ExcelNameErr}
                label="ชื่อ"
              />
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "black",
                  borderColor: "transparent",
                  backgroundColor: "#F8F9FA",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button>

              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#FF0000",
                  borderColor: "transparent",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => EditAndAddTopic()}
              >
                {Loading ? (
                  <CircularProgress
                    sx={{
                      color: "#FFFFFF",
                    }}
                    size={24}
                  />
                ) : (
                  "ตกลง"
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={OpenAddTitle}
            onClose={() => handleClose()}
            fullWidth={true}
            maxWidth="xs"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogContent>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                onChange={(e) => setTitleName(e.target.value)}
                value={TitleName}
                required
                // error={ExcelNameErr}
                label="Title"
              />
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "black",
                  borderColor: "transparent",
                  backgroundColor: "#F8F9FA",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button>

              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#FF0000",
                  borderColor: "transparent",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => EditAndAddTopic()}
              >
                {Loading ? (
                  <CircularProgress
                    sx={{
                      color: "#FFFFFF",
                    }}
                    size={24}
                  />
                ) : (
                  "ตกลง"
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openCancel}
            onClose={() => handleClose()}
            fullWidth={true}
            maxWidth="xs"
            classes={{ paper: classes.dialogPaper }}
          >
            <DialogTitle>ยืนยันที่จะลบหรือไม่</DialogTitle>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: 20,
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "black",
                  borderColor: "transparent",
                  backgroundColor: "#F8F9FA",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => handleClose()}
              >
                ยกเลิก
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  backgroundColor: "#FF0000",
                  borderColor: "transparent",
                  padding: 10,
                  marginRight: 10,
                  width: 80,
                }}
                onClick={() => EditAndAddTopic()}
              >
                {Loading ? (
                  <CircularProgress
                    sx={{
                      color: "#FFFFFF",
                    }}
                    size={24}
                  />
                ) : (
                  "ตกลง"
                )}
              </Button>
            </DialogActions>
          </Dialog>

          <DialogForAPI
            DialogOpen={open}
            DialogClose={handleCloseDialog}
            message={message}
          />
        </div>
      </Paper>
    </div>
  );
}
